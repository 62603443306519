<template>
  <div id="marker">
    <SiteNav></SiteNav>
    <section id="about">
      <div class="col1">
        <div class="container">
          <h2>Welcome to Wellthmark!</h2>
          <hr>
        </div>
        <div class="container">
          <h3>Wellth: Your health in one number</h3>
          <hr>
          <p>
            Our Wellthmark algorithm calculates your Wellth - 
            a number between 0 and 100 representing how healthy and fit you are - 
            based on your individual health and wellness markers.
          </p>
        </div>
        <div class="container">
          <h3>Your one stop shop for tracking your health</h3>
          <hr>
          <p>
            When you improve your markers, your Wellth goes up. 
            Each marker has an optimal result based on professionally accepted standards 
            in medicine, fitness, and athletics.
          </p>
        </div>
        <div class="container">
          <h3>What's in store for the future?</h3>
          <hr>
          <p>
            Thank you for being one of our earliest users! At Wellthmark, 
            we crave feedback, so please don't hesitate to leave us 
            some. You can help shape our future roadmap! Things on 
            our to-do list include API integration with popular 
            wearables, global and demographic benchmarking, teams 
            creation to compete with and cheer on friends and family, 
            and much more. Please reach out any time. We're only just 
            getting started.
          </p>
        </div>
        <div class="container">
          <hr>
          <footer>
            <p>
              &#169; 2021 Wellthmark. All rights reserved.
            </p>
            <p>
              Contact us at help@wellthmark.com
            </p>
            <p>
              <a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a>
            </p>
            <p>
              <a href="https://forms.gle/4sxiLjPDLEaWK8LWA" target="_blank">Subscribe</a>
            </p>
          </footer>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/SiteNav'

export default {
  components: {
    'SiteNav': SiteNav
  },
  data() {
    return {
      name: '',
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name
      })

      this.name = ''

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

section {
  position: fixed;
  // display: flex;
  // flex-direction: row;
  top: 3.6rem;
  height: 90vh;
  width: 100%;
  padding: 1rem 20%;

  @media screen and (max-width: 800px) {
    padding: 1rem;
    position: relative;
    height: auto;
  }

  // @media screen and (max-width: 800px) {
  //   display: block;
  // }
}

.col1 {
  // flex: 100%;
  height: 100%;
  width: 100%;
  padding: 0rem;
  overflow: auto;
  // min-width: 350px;

  // @media screen and (max-width: 800px) {
    // height: auto;
  // }
}

.container {
  padding: 1rem;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh;

  * {
    margin: auto;
    padding: 1vh;
    color: $greyedOut;

    a {
      // color: $secondary;
      opacity: 1;
      // text-decoration: underline;
      // padding: 0.5rem;

      &:hover {
        // background-color: #000;
        color: $primary;
      }
    }
  }
}

</style>